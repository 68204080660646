<template>
    <el-dialog
            title="入库申请"
            :visible.sync="dialogVisible"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="120px"
                 size="small">
            <el-form-item label="入库原因" prop="reason">
                <el-select
                        :disabled="inputType"
                        v-model="inputForm.reason"
                        clearable
                        placeholder="请选择入库原因"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('outbound_reason')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入库申请人" prop="applicantName">
                <el-input
                        v-model.trim="inputForm.applicantName"
                        placeholder="请输入入库申请人(限50字)"
                        maxlength="50"
                        :disabled="inputType"
                        clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="具体原因" prop="specificReasons">
                <el-input type="textarea" rows="1" resize="none"
                          v-model.trim="inputForm.specificReasons" maxlength="50"
                          placeholder="请输入具体原因(限50字)"
                          clearable></el-input>
            </el-form-item>
            <el-form-item label="入库时间" prop="operationDate">
                <el-date-picker
                        v-model="inputForm.operationDate"
                        type="date"
                        clearable
                        style="width: 100%"
                        value-format="yyyy-MM-dd"
                        :picker-options="outBoundSet"
                        placeholder="选择出库时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="备注说明" prop="remark">
                <el-input type="textarea" rows="5" resize="none"
                          v-model.trim="inputForm.remark" maxlength="500"
                          placeholder="详细描述藏品出库的详细原因以及备注(限500字)"
                          clearable></el-input>
            </el-form-item>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose(1)">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()" v-noMoreClick>确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "warehousingPop",
    data(){
        return{
            dialogVisible:false,
            inputForm:{
                reason:'',
                applicant: '',
                applicantName:'',
                specificReasons:'',
                operationDate: '',
                remark: '',
            },
            rules:{
                applicantName: [
                    {required: true, message: '请输入入库申请人', trigger: 'blur'}
                ],
                specificReasons: [
                    {required: true, message: '请输入具体原因', trigger: 'blur'}
                ],
                operationDate: [
                    {required: true, message: '请选择入库时间', trigger: 'change'}
                ],
            },
            inputType:true,
            outBoundSet: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            selectData:{},
        }
    },

    methods:{
        init(row,inputForm){
            this.dialogVisible = true
            this.selectData = row
            this.inputForm = inputForm
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            this.inputForm.applicant = userInfo.id
            this.inputForm.applicantName = userInfo.name
        },

        sureData(){
            this.inputForm.id = null
            this.$axios(this.api.collection.collectionwarehouseorder, {
                ...this.inputForm,
                orderType: 0,
                collectionWarehouseOrderItems:[this.selectData],
            }, 'post').then(data => {
                if (data.status) {
                    this.$message.success('提交成功！')
                    this.handleClose()
                    this.$emit('refresh')
                } else {
                    this.$message.error(data.msg)
                }
            })
        },

        handleClose(){
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>