<template>
    <el-dialog
            title="确认入库"
            :visible.sync="dialogVisibl1e"
            width="1000px"
            class="dialogForm"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div class="minTitle">总账藏品清单</div>
        <div class="minTitle2" style="margin-left: 25px">合计{{ generalList.length }}件/套 藏品</div>
        <el-table
                :data="generalList"
                size="small"
                style="padding-left: 20px"
                height="300px"
                ref="multipleTable1"
                class="table"
                :header-cell-style="{background:'#F9DFDF'}"
        >
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                    <div class="flex_a_c">
                        <div v-if="scope.row.collectionData.carded == 0"
                             class="icon-wenwukapian iconfont listIcon"></div>
                        <el-image
                                style="width: 36px; height: 36px"
                                :src="scope.row.collectionData.picMasterViewUrl"
                                :fit="'cover'"
                                :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                        style="width: 36px; height: 36px"
                                        :src="require('@/assets/img/default.png')"
                                        :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"
                             show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.collectionName }}
                </template>
            </el-table-column>
            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.helpNum }}
                </template>
            </el-table-column>
            <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.generalNum }}
                </template>
            </el-table-column>
            <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.oneTypeName }}
                </template>
            </el-table-column>
            <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
                </template>
            </el-table-column>
            <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getTypeList(scope.row.collectionData.collectionType) }}
                </template>
            </el-table-column>
            <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.integrityInfo }}
                </template>
            </el-table-column>
            <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.integrity }}
                </template>
            </el-table-column>
            <el-table-column prop="actualLevel" label="实完残程度" width="200px">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.actualLevel"
                               size="small"
                               placeholder="请选择完残程度"
                               style="width: 80%"
                               clearable>
                        <el-option
                            v-for="item in integrityList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <!--            <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>-->
            <!--                <template slot-scope="scope">-->
            <!--                    {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}-->
            <!--                </template>-->
            <!--            </el-table-column>-->
        </el-table>

        <div class="minTitle">辅助账藏品清单</div>
        <div class="minTitle2" style="margin-left: 25px">合计{{ auxiliaryList.length }}件/套 藏品</div>
        <el-table
                :data="auxiliaryList"
                size="small"
                style="padding-left: 20px"
                height="300px"
                ref="multipleTable1"
                class="table"
                :header-cell-style="{background:'#F9DFDF'}"
        >
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                    <div class="flex_a_c">
                        <div v-if="scope.row.collectionData.carded == 0"
                             class="icon-wenwukapian iconfont listIcon"></div>
                        <el-image
                                style="width: 36px; height: 36px"
                                :src="scope.row.collectionData.picMasterViewUrl"
                                :fit="'cover'"
                                :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                        style="width: 36px; height: 36px"
                                        :src="require('@/assets/img/default.png')"
                                        :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"
                             show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.collectionName }}
                </template>
            </el-table-column>
            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.helpNum }}
                </template>
            </el-table-column>
            <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.generalNum }}
                </template>
            </el-table-column>
            <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.oneTypeName }}
                </template>
            </el-table-column>
            <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
                </template>
            </el-table-column>
            <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getTypeList(scope.row.collectionData.collectionType) }}
                </template>
            </el-table-column>
            <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.integrityInfo }}
                </template>
            </el-table-column>
            <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.collectionData.integrity }}
                </template>
            </el-table-column>
            <el-table-column prop="actualLevel" label="实完残程度" width="200px">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.actualLevel"
                               size="small"
                               placeholder="请选择完残程度"
                               style="width: 80%"
                               clearable>
                        <el-option
                                v-for="item in integrityList"
                                :key="item.id"
                                :label="item.levelName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <!--            <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>-->
            <!--                <template slot-scope="scope">-->
            <!--                    {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}-->
            <!--                </template>-->
            <!--            </el-table-column>-->
        </el-table>


        <div class="text_center dialog-footer" slot="footer" style="margin-top: 10px">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()" v-noMoreClick>确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "confirmReceiptList",
    data() {
        return {
            dialogVisibl1e: false,
            integrity: '',
            integrityList: [
                {
                    id: '残缺',
                    levelName: '残缺',
                },
                {
                    id: '基本完整',
                    levelName: '基本完整',
                },
                {
                    id: '完整',
                    levelName: '完整',
                },
                {
                    id: '严重残缺',
                    levelName: '严重残缺',
                },
            ],
            leaveList: [],
            typeList: [],

            generalList: [],
            auxiliaryList: [],
        }
    },

    mounted() {
        this.$axios(this.api.collection.listLevelSelect).then(data => {
            if (data.status) {
                this.leaveList = data.data
            }
        })
        this.$axios(this.api.collection.listSelect).then(data => {
            if (data.status) {
                this.typeList = data.data
            }
        })
    },

    methods: {
        init(list1, list2) {
            this.generalList = list1
            this.auxiliaryList = list2
            this.dialogVisibl1e = true
        },

        sureData() {
            if (this.generalList.length + this.auxiliaryList.length == 0){
                this.$message.error('暂无可入库资源')
                return;
            }
            let data1 = this.generalList.filter(item => {
                return item.actualLevel == null
            })
            let data2 = this.auxiliaryList.filter(item => {
                return item.actualLevel == null
            })
            if (data1.length = data2.length != 0) {
                this.$message.error('请选择实完残程度')
                return
            }
            this.$axios(this.api.collection.collectionwarehouseorderInbound, [
                ...this.generalList,
                ...this.auxiliaryList,
            ], 'post').then((res) => {
                if (res.status) {
                    this.$message.success('入库成功')
                    this.$emit('rukwus')
                    this.handleClose()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        getLeaveList(leave) {
            let data = this.leaveList.filter(item => {
                return item.level == leave
            })
            if (data.length) {
                return data[0].levelName
            }
        },

        getTypeList(type) {
            let data = this.typeList.filter(item => {
                return item.id == type
            })
            if (data.length) {
                return data[0].collectionTypeName
            }
        },

        handleClose() {
            this.integrity = ''
            this.dialogVisibl1e = false
        },
    },
}
</script>

<style scoped>
.minTitle {
    font-weight: bold;
    font-size: 14px;
}

.minTitle2 {
    font-size: 12px;
    margin-top: 20px;
}
</style>