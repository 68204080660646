<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div class="wenhaoss">
            <el-popover
                placement="top-start"
                title="温馨提示"
                width="400"
                trigger="hover"
                >
                <div>{{ orderType == 1 ? '列表数据只展示库存状态为在库的藏品。' : '列表数据只展示在库状态为待入库并且没有出库记录的藏品。'}}</div>
                <el-image
                    slot="reference"
                    style="width: 20px; height: 20px"
                    :src="require('@/assets/img/wenhao.png')"
                    :fit="'fill'"></el-image>
            </el-popover>
        </div>
        <div>
            <el-form size="small" :inline="true" ref="inputForm" :model="inputForm" label-width="100px">
                <el-form-item label="藏品名称" prop="collectionName">
                    <el-input v-model.trim="inputForm.collectionName"
                              placeholder="请输入藏品名称(限50字)"
                              maxlength="50" clearable></el-input>
                </el-form-item>
                <el-form-item label="藏品编号" prop="num">
                    <el-input v-model.trim="inputForm.num"
                              placeholder="请输入藏品编号(限50字)"
                              maxlength="50"
                              clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="藏品级别" prop="collectionLevel">
                    <el-select v-model="inputForm.collectionLevel" clearable
                               placeholder="请选择藏品级别" style="width: 100%;">
                        <el-option
                                v-for="item in leaveList"
                                :key="item.level"
                                :label="item.levelName"
                                :value="item.level">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="藏品类型" prop="collectionType">
                    <el-select v-model="inputForm.collectionType"
                               placeholder="请选择藏品类型"
                               style="width: 100%"
                               clearable>
                        <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.collectionTypeName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="完残程度" prop="integrity">
                    <el-select v-model="inputForm.integrity"
                               placeholder="请选择完残程度"
                               style="width: 100%"
                               clearable>
                        <el-option
                                v-for="item in integrityList"
                                :key="item.id"
                                :label="item.levelName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <span>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询</el-button>
                        <el-button size="small" type="primary" icon="el-icon-refresh-right" @click="resetting()">重置</el-button>
                </span>
            </el-form>
            <el-table
                    :data="dataList"
                    size="small"
                    style="margin-left: 20px"
                    height="300px"
                    class="table"
                    v-loading="loading"
                    ref="multipleTable"
                    :row-key="'collectionData.id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="name" label="图标" width="120px">
                    <template slot-scope="scope">
                        <div class="flex_a_c">
                            <div v-if="scope.row.collectionData.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                            <el-image
                                style="width: 36px; height: 36px"
                                :src="scope.row.collectionData.picMasterViewUrl"
                                :fit="'cover'"
                                :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                            >
                                <div slot="error" class="image-slot">
                                    <el-image
                                        style="width: 36px; height: 36px"
                                        :src="require('@/assets/img/default.png')"
                                        :fit="'cover'">
                                    </el-image>
                                </div>
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="collectionName" label="藏品名称"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.collectionName }}
                    </template>
                </el-table-column>
                <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.helpNum }}
                    </template>
                </el-table-column>
                <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.generalNum }}
                    </template>
                </el-table-column>
                <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.oneTypeName }}
                    </template>
                </el-table-column>
                <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
                    </template>
                </el-table-column>
                <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getTypeList(scope.row.collectionData.collectionType) }}
                    </template>
                </el-table-column>
                <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.integrityInfo }}
                    </template>
                </el-table-column>
                <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.collectionData.integrity }}
                    </template>
                </el-table-column>
                <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("cStore_state", scope.row.collectionData.cStoreState, '-') }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
            <div class="text_center" style="margin-top: 20px">
                <el-button size="small" @click="handleClose(1)">取消</el-button>
                <el-button size="small" type="primary" @click="sureData()" v-noMoreClick>确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "addInventory",
    data() {
        return {
            dialogVisible: false,
            title: '',
            inputForm: {
                collectionName: '',
                num: '',
                collectionLevel: '',
                collectionType: '',
                integrity: '',
            },
            loading: false,
            dataList: [],
            dataListSelect: [],
            oldSelectData: [],
            leaveList: [],
            typeList: [],
            integrityList: [
                {
                    id: '残缺',
                    levelName: '残缺',
                },
                {
                    id: '基本完整',
                    levelName: '基本完整',
                },
                {
                    id: '完整',
                    levelName: '完整',
                },
                {
                    id: '严重残缺',
                    levelName: '严重残缺',
                },
            ],

            pageNo: 1,
            pageSize: 10,
            total: 0,
            listType: 0, //0征集藏品,1总账藏品,2辅助帐藏品,3不可移动文物
            orderType: '', //1出库 0入库
        }
    },

    mounted() {
        this.$axios(this.api.collection.listLevelSelect).then(data => {
            if (data.status) {
                this.leaveList = data.data
            }
        })
        this.$axios(this.api.collection.listSelect).then(data => {
            if (data.status) {
                this.typeList = data.data
            }
        })
    },

    methods: {
        init(num, data, orderType) {
            if (num == 1) {
                this.title = '总账藏品'
            } else if (num == 2) {
                this.title = '辅助帐藏品'
            }
            this.orderType = orderType
            this.oldSelectData = data
            this.listType = num
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.resetting()
            })
        },

        //获取列表数据
        getDataList(type, dividePage) {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.pageNo = 1
                    }
                    if (dividePage == 1) {
                        this.$refs.multipleTable.clearSelection()
                    }
                    this.loading = true
                    this.searchRecord = {
                        ...this.inputForm,
                        entryState: this.listType,
                        orderType: this.orderType,
                        current: this.pageNo,
                        size: this.pageSize,
                    }
                    this.$axios(this.api.collection.listPendingStorageCollection, this.searchRecord, 'get').then((res) => {
                        if (res.status) {
                            this.dataList = res.data.records
                            this.total = parseInt(res.data.total)
                            this.$nextTick(() => {
                                this.$refs.multipleTable.doLayout()
                            })
                            if (this.dataList.length == 0 && this.pageNo > 1) {
                                this.pageNo--
                                this.getDataList()
                            }
                        } else {
                            this.$message.error('查询失败');
                        }
                        this.loading = false
                    })
                }
            })
        },

        addData(row){
            if (this.listType == 1){
                this.$router.push({
                    path: '/collection/accounts/addCollection',
                    query: {
                        butType: 3,
                        id: row.id,
                        archivesBasicDataId: row.archivesBasicDataId,
                        typePage: 'ledger',
                        see: 1
                    }
                })
            }else {
                this.$router.push({
                    path: '/collection/accounts/addCollection',
                    query: {
                        butType: 3,
                        id: row.id,
                        archivesBasicDataId: row.archivesBasicDataId,
                        typePage: 'auxiliaryAccount',
                        see: 1
                    }
                })
            }
        },

        sureData() {
            this.oldSelectData.push(
                ...this.dataListSelect
            )
            this.handleClose()
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            console.log(val)
            this.dataListSelect = val
        },

        getLeaveList(leave) {
            let data = this.leaveList.filter(item => {
                return item.level == leave
            })
            if (data.length) {
                return data[0].levelName
            }
        },

        getTypeList(type){
            let data = this.typeList.filter(item => {
                return item.id == type
            })
            if (data.length) {
                return data[0].collectionTypeName
            }
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', 2);
        },

        handleClose(num) {
            if (num){
                this.$confirm(`点击确定后，将不会保存你所选中的总账藏品数据列表?`, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dialogVisible = false
                })
            }else {
                this.dialogVisible = false
            }
        },

        // 设置当前行是否禁用【可以选择】 参数row 传入当前行数据
        canBeSelect(row) {
            let reapet = this.oldSelectData.filter(item => {
                return item.collectionData.id == row.collectionData.id
            })
            if (!reapet.length) {
                return true; // 返回true 可以选择
            } else {
                return false; // 返回false 禁止选择
            }
        },
    },
}
</script>

<style scoped>
.wenhaoss{
    position: absolute;
    top: 22px;
    left: 132px;
}
</style>
